import { ref, watch, computed } from '@vue/composition-api'
// import store from '@/store'
import axios from 'axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCustomerSubscriptions() {
  // Use toast
  const toast = useToast()

  const refCustomerSubscriptionsTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: '#', sortable: true },
    { key: 'id', sortable: false },
    { key: 'transaction_id', sortable: false },
    { key: 'name', sortable: false },
    { key: 'price', sortable: false },
    { key: 'balance', sortable: false },
    { key: 'paid', sortable: false },
    { key: 'payment_method', sortable: false },
    { key: 'payment_date', sortable: false },
  ]
  const perPage = ref(15)
  const id = ref('')
  const totalCustomerSubscriptions = ref(0)
  const currentPage = ref(1)
  const lastPage = ref(1)
  const perPageOptions = [15, 30, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refCustomerSubscriptionsTable.value ? refCustomerSubscriptionsTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCustomerSubscriptions.value,
    }
  })

  const refetchData = () => {
    refCustomerSubscriptionsTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, id], () => {
    refetchData()
  })

  const fetchCustomerSubscriptions = (ctx, callback) => {
    axios
      .get(`${process.env.VUE_APP_BASE_API_URL}/user/subscriptions/${id.value}?paginate&page=${currentPage.value}&perPage=${perPage.value}`, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      })
      .then(res => {
        const customerSubscriptions = res.data.data.items
        totalCustomerSubscriptions.value = res.data.data.total
        currentPage.value = res.data.data.current_page
        lastPage.value = res.data.data.last_page

        callback(customerSubscriptions)
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching Customer Subscriptions' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchCustomerSubscription = (subscriptionId, customerId) => (
    axios.get(`${process.env.VUE_APP_BASE_API_URL}/user/subscriptions/${subscriptionId}/${customerId}`, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    })
      .then(res => res.data.data.user_subscription)
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching subscription',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  )

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchCustomerSubscriptions,
    fetchCustomerSubscription,
    tableColumns,
    perPage,
    currentPage,
    lastPage,
    totalCustomerSubscriptions,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCustomerSubscriptionsTable,
    id,

    refetchData,
  }
}
