<template>
  <div>
    <div class="card shadow-none border p-1 mb-0">
      <h3>Subscriptions</h3>
    </div>
    <b-table
      ref="refCustomerSubscriptionsTable"
      :items="fetchCustomerSubscriptions"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
      :current-page="currentPage"
      :per-page="perPage"
      tbody-tr-class="cursor-pointer"
      @row-clicked="handleRowClick"
    >

      <template #cell(id)="data">
        {{ data.item.id }}
      </template>

      <template #cell(transaction_id)="data">
        {{ data.item.transaction.id }}
      </template>

      <template #cell(name)="data">
        {{ data.item.subscription.name }}
      </template>

      <template #cell(price)="data">
        {{ data.item.subscription.price }}
      </template>

      <template #cell(balance)="data">
        {{ data.item.subscription.balance }}
      </template>

      <template #cell(paid)="data">
        <div class="d-flex align-items-center">
          <span :class="`bullet bullet-sm bullet-${data.item.payment_status === 'Paid' ? 'success' : 'danger'} mx-25`" />
          <span class="text-nowrap">
            {{ data.item.payment_status === 'Paid' ? 'Yes' : 'No' }}
          </span>
        </div>
      </template>

      <template #cell(payment_method)="data">
        {{ data.item.payment_method.name }}
      </template>

      <template #cell(payment_date)="data">
        {{ data.item.transaction.created_at }}
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="12"
          sm="12"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Entries</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
        </b-col>
      </b-row>
      <b-row>

        <b-col
          cols="12"
          sm="6"
          md="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          md="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalCustomerSubscriptions"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BTable,
  VBToggle,
  BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useCustomerSubscriptions from './useCustomerSubscriptions'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BPagination,
    vSelect,
    // OrderDetails: () => import('../../orders/OrderDetails.vue'),
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    customerId: {
      type: Number,
      required: true,
    },
  },
  setup(props, context) {
    const selectedSubscription = ref(null)
    const {
      fetchCustomerSubscriptions,
      tableColumns,
      perPage,
      currentPage,
      totalCustomerSubscriptions,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCustomerSubscriptionsTable,
      id,

      refetchData,
    } = useCustomerSubscriptions()

    onMounted(() => {
      id.value = props.customerId
    })

    const handleRowClick = record => {
      selectedSubscription.value = record.id
      context.root.$router.push(`/subscriptions/print/${record.id}/${props.customerId}`)
    }

    return {
      fetchCustomerSubscriptions,
      tableColumns,
      perPage,
      currentPage,
      totalCustomerSubscriptions,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCustomerSubscriptionsTable,
      selectedSubscription,
      handleRowClick,

      refetchData,
    }
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
