<!-- eslint-disable vue/html-self-closing -->
<template>
  <div>
    <button
      id="print-btn"
      class="btn btn-success"
      @click="handlePrint"
    >
      Print
    </button>
    <router-link
      :to="`/customers/${customerId}`"
      class="btn btn-primary mx-2"
    >
      Go To Customer Details
    </router-link>
    <div
      v-if="Object.keys(subscription).length > 0"
      id="subscription-invoice-POS"
    >

      <center id="top">
        <div class="logo">
          <img
            :src="require('@/assets/images/logo/logo.svg')"
            width="80"
            height="80"
          >
        </div>
        <div class="info">
          <h1>Wash Capital Laundry</h1>
          <h5 class="font-size">
            <span>Mobile: +96594194196</span>
          </h5>
          <h5 class="font-size">
            <span>Subscription Receipt إيصال الاشتراك</span>
          </h5>
        </div><!--End Info-->
      </center><!--End InvoiceTop-->
      <div id="mid-1">
        <div class="info">
          <!-- <h2>Contact Info</h2> -->
          <p class="info-container font-size">
            <span>
              <strong>رقم الاشتراك</strong>
              <br>
              <strong>Subscription ID</strong>
              <br>
              {{ subscription.id }}
            </span>
            <span>
              <strong>رقم معاملة الدفع</strong>
              <br>
              <strong>Transaction ID</strong>
              <br>
              #{{ subscription.transaction.id }}
            </span>
            <span v-if="subscription.transaction.paid_date">
              <strong>تاريخ الدفع</strong>
              <br>
              <strong>Payment Date</strong>
              <br>
              {{ subscription.transaction.paid_date }}
            </span>
          </p>
        </div>
      </div><!--End Invoice Mid-->
      <div id="mid">
        <div class="info">
          <h2 class="font-size">
            <span><strong>Contact Info بيانات التواصل</strong></span>
          </h2>
          <p class="info-container font-size">
            <span v-if="subscription.user.full_name">
              <strong>Name الاسم</strong>
              <br>
              {{ subscription.user.full_name }}
            </span>
            <!--            <span v-if="subscription.user.email">-->
            <!--              <strong>Email البريد الإلكتروني</strong>-->
            <!--              <br>-->
            <!--              {{ subscription.user.email }}-->
            <!--            </span>-->
            <span
              v-if="subscription.user.phone"
              class="phone-box"
            >
              <strong>Phone الهاتف</strong>
              <br>
              {{ subscription.user.phone }}
            </span>
          </p>
<!--          <p class="font-size">-->
<!--            <span v-if="fullAddress">-->
<!--              <strong>Address العنوان</strong>-->
<!--              <br>-->
<!--              {{ fullAddress }}-->
<!--            </span>-->
<!--          </p>-->
        </div>
      </div><!--End Invoice Mid-->
      <div
        v-if="user.subscriptions_balance || user.wallet_amount"
        id="mid-1"
      >
        <div class="info">
          <!-- <h2>Info</h2> -->
          <p class="info-container font-size">
            <span v-if="user.subscriptions_balance">
              <strong>Subscriptions Balance رصيد الاشتراكات</strong>
              <br>
              {{ user.subscriptions_balance }} KD
            </span>
            <span v-if="user.wallet_amount">
              <strong>Wallet Amount رصيد المحفظة</strong>
              <br>
              {{ user.wallet_amount }} KD
            </span>
          </p>
        </div>
      </div>
      <!--End Invoice Mid-->
      <div id="bot">

        <div
          v-if="Object.keys(subscription).length > 0"
          id="table"
        >
          <table>
            <tr class="tabletitle table-header">
              <td
                class="item"
                colspan="2"
              >
                <h2 class="font-size">
                  <span>الاشتراك</span>
                  <br>
                  <span>Subscription</span>
                  <br>
                </h2>
              </td>
              <td class="Rate">
                <h2 class="font-size">
                  <span>الرصيد</span>
                  <br>
                  <span>Balance</span>
                  <br>
                </h2>
              </td>
              <td class="Rate">
                <h2 class="font-size text-center">
                  <span>السعر</span>
                  <br>
                  <span>Price</span>
                  <br>
                </h2>
              </td>
            </tr>
            <tbody>
              <tr>
                <td
                  class="tableitem"
                  colspan="2"
                >
                  <span class="itemtext font-size">
                    {{ subscription.subscription.name }}
                  </span>
                </td>
                <td class="tableitem text-center">
                  <span class="itemtext font-size">
                    {{ subscription.subscription.balance }}
                  </span>
                </td>
                <td class="tableitem text-center">
                  <span class="itemtext font-size">
                    {{ subscription.subscription.price }}
                  </span>
                </td>
              </tr>
            </tbody>
            <!-- payment details -->
            <tr
              v-if="subscription.payment_method"
              class="tabletitle"
            >
              <td class="Rate">
                <h2 class="font-size">
                  <span>Payment Method طريقة الدفع</span>
                </h2>
              </td>
              <td></td>
              <td></td>
              <td
                class="payment"
                colspan="2"
              >
                <h2 class="font-size">
                  {{ subscription.payment_method.name }}
                </h2>
              </td>
            </tr>
          </table>
        </div><!--End Table-->

      </div><!--End InvoiceBot-->
      <div
        id="legalcopy"
        class="font-size"
      >
        <span class="font-size">{{ footer }}</span>
      </div>
    </div><!--End Invoice-->
  </div>
</template>
<script>
import { onMounted, ref } from '@vue/composition-api'
import axios from 'axios'
import { Printd } from 'printd'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useCustomerSubscriptions from './useCustomerSubscriptions'

export default {
  props: {},
  setup(props, context) {
    const toast = useToast()
    const subscriptionId = context.root.$route.params.id
    const { customerId } = context.root.$route.params
    const subscription = ref({})
    const fullAddress = ref('')
    const footer = ref('')
    const user = ref('')
    const {
      fetchCustomerSubscription,
    } = useCustomerSubscriptions()

    const handlePrint = () => {
      const cssText = `@media print {
          .page-break {
              display: block;
              page-break-before: always;
          }
      }

      /* arabic */
      @font-face {
        font-family: 'El Messiri';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/elmessiri/v22/K2F0fZBRmr9vQ1pHEey6MoiAAhLz.woff2) format('woff2');
        unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
      }
      /* cyrillic */
      @font-face {
        font-family: 'El Messiri';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/elmessiri/v22/K2F0fZBRmr9vQ1pHEey6MomAAhLz.woff2) format('woff2');
        unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
      }
      /* latin-ext */
      @font-face {
        font-family: 'El Messiri';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/elmessiri/v22/K2F0fZBRmr9vQ1pHEey6MoOAAhLz.woff2) format('woff2');
        unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'El Messiri';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/elmessiri/v22/K2F0fZBRmr9vQ1pHEey6Mo2AAg.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* arabic */
      @font-face {
        font-family: 'El Messiri';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/elmessiri/v22/K2F0fZBRmr9vQ1pHEey6MoiAAhLz.woff2) format('woff2');
        unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
      }
      /* cyrillic */
      @font-face {
        font-family: 'El Messiri';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/elmessiri/v22/K2F0fZBRmr9vQ1pHEey6MomAAhLz.woff2) format('woff2');
        unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
      }
      /* latin-ext */
      @font-face {
        font-family: 'El Messiri';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/elmessiri/v22/K2F0fZBRmr9vQ1pHEey6MoOAAhLz.woff2) format('woff2');
        unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'El Messiri';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/elmessiri/v22/K2F0fZBRmr9vQ1pHEey6Mo2AAg.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* arabic */
      @font-face {
        font-family: 'Tajawal';
        font-style: normal;
        font-weight: 200;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/tajawal/v9/Iurf6YBj_oCad4k1l_6gHrRpiYlJ.woff2) format('woff2');
        unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
      }
      /* latin */
      @font-face {
        font-family: 'Tajawal';
        font-style: normal;
        font-weight: 200;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/tajawal/v9/Iurf6YBj_oCad4k1l_6gHrFpiQ.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* arabic */
      @font-face {
        font-family: 'Tajawal';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/tajawal/v9/Iura6YBj_oCad4k1nzSBC45I.woff2) format('woff2');
        unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
      }
      /* latin */
      @font-face {
        font-family: 'Tajawal';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/tajawal/v9/Iura6YBj_oCad4k1nzGBCw.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* arabic */
      @font-face {
        font-family: 'Tajawal';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/tajawal/v9/Iurf6YBj_oCad4k1l4qkHrRpiYlJ.woff2) format('woff2');
        unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
      }
      /* latin */
      @font-face {
        font-family: 'Tajawal';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/tajawal/v9/Iurf6YBj_oCad4k1l4qkHrFpiQ.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* arabic */
      @font-face {
        font-family: 'Tajawal';
        font-style: normal;
        font-weight: 900;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/tajawal/v9/Iurf6YBj_oCad4k1l7KmHrRpiYlJ.woff2) format('woff2');
        unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
      }
      /* latin */
      @font-face {
        font-family: 'Tajawal';
        font-style: normal;
        font-weight: 900;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/tajawal/v9/Iurf6YBj_oCad4k1l7KmHrFpiQ.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }

      #subscription-invoice-POS {
          padding: 0 10mm 2mm 0;
          margin: 0 auto;
          width: 100mm;
          background: #FFF;
          font-family: 'arial', serif !important;
      }

      #subscription-invoice-POS .font-size {
        font-size: 16px !important;
      }

      #subscription-invoice-POS .info-container {
        display: flex;
        justify-content: start;
        word-break: break-word;
      }

      #subscription-invoice-POS .info-container span:not(:last-child) {
        border-right: 1px solid #000;
        margin-right: 5px;
        padding-right: 5px;
      }

      #subscription-invoice-POS ::selection {
          background: #f31544;
          color: #FFF;
      }

      #subscription-invoice-POS ::moz-selection {
          background: #f31544;
          color: #FFF;
      }

      #subscription-invoice-POS h1 {
          font-size: 1.5em;
          color: #000;
          margin-bottom: 0px !important;
          margin-top: 0px !important;
      }

      #subscription-invoice-POS h2 {
          font-size: 10px;
          margin-bottom: 0px !important;
          margin-top: 0px !important;
      }

      #subscription-invoice-POS h3 {
          font-size: 1.2em;
          font-weight: 300;
          line-height: 2em;
          margin-bottom: 0px !important;
          margin-top: 0px !important;
      }

      #subscription-invoice-POS h5 {
          margin-bottom: 0px !important;
          margin-top: 0px !important;
      }

      #subscription-invoice-POS p {
          font-size: 10px;
          font-weight: bold;
          color: #000;
          line-height: 1.2em;
          margin-bottom: 0 !important;
      }

      #subscription-invoice-POS #top, #subscription-invoice-POS #mid, #subscription-invoice-POS #mid-1, #subscription-invoice-POS #mid-2, #subscription-invoice-POS #bot, #subscription-invoice-POS .table-header {
        border-bottom: 1px dashed #000;
        padding-bottom: 20px !important;
      }

      #subscription-invoice-POS #bot {
        padding-top: 0px !important;
      }

      #subscription-invoice-POS .balance-row { /* , #subscription-invoice-POS .table-header */
        border-top: 1px dashed #000;
      }

      #subscription-invoice-POS #top {
          min-height: 80px;
      }

      #subscription-invoice-POS #mid {
          min-height: 50px;
      }


      #subscription-invoice-POS #bot {
          min-height: 50px;
      }

      #subscription-invoice-POS #bot h2 {
          font-size: 10px;
      }

      #subscription-invoice-POS #top .logo {
          height: 80px;
          width: 80px;
      }

      #subscription-invoice-POS .clientlogo {
          float: left;
          height: 60px;
          width: 60px;
          background: url(http://michaeltruong.ca/images/client.jpg) no-repeat;
          background-size: 60px 60px;
          border-radius: 50px;
      }

      #subscription-invoice-POS .info {
          display: block;
          margin-left: 0;
      }

      #subscription-invoice-POS .title {
          float: right;
      }

      #subscription-invoice-POS .title p {
          text-align: right;
      }

      #subscription-invoice-POS table {
          width: 100%;
          border-collapse: collapse;
      }

      #subscription-invoice-POS .tabletitle {
          font-size: 10px;
          font-weight: bold;
          margin: 3px;
          background: #EEE;
      }

      #subscription-invoice-POS .service {
          border-bottom: 1px solid #000;
      }

      #subscription-invoice-POS .item {
          // width: 24mm;
      }

      #subscription-invoice-POS .tableitem {
          line-height: 1;
          padding-bottom: 5px;
      }

      #subscription-invoice-POS .itemtext {
          font-size: 10px;
          font-weight: bold;
          line-height: 1.6;
      }

      #subscription-invoice-POS .noteitem {
          line-height: 1.45;
      }

      #subscription-invoice-POS .notetext {
          margin-inline: .5rem;
      }

      #subscription-invoice-POS .payment {
          padding: 0 5px;
      }

      #subscription-invoice-POS #legalcopy {
          margin-top: 5mm;
          margin-right: 1em;
          margin-left: 1em;
      }

      #subscription-invoice-POS .phone-box {
        flex-shrink: 0;
      }`

      const d = new Printd()
      setTimeout(() => {
        d.print(document.getElementById('subscription-invoice-POS'), [cssText])
      }, 50)
    }

    onMounted(() => {
      fetchCustomerSubscription(subscriptionId, customerId).then(res => {
        subscription.value = res

        axios.get(`${process.env.VUE_APP_BASE_API_URL}/pos/users/${res.user.id}`, {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        }).then(userRes => {
          if (userRes.data.code === 200) {
            user.value = userRes.data.data.user

            axios.get(`${process.env.VUE_APP_BASE_API_URL}/configurations?scope=3`)
              .then(result => {
                if (result.data.code === 200) {
                  footer.value = result.data.data.configurations.pos_print_footer
                  document.getElementById('legalcopy').innerHTML = result.data.data.configurations.pos_print_footer
                  handlePrint()
                }
              })
              .catch(() => {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error fetching configurations list',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching user',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      })
    })

    return {
      customerId,
      subscription,
      user,
      fullAddress,
      footer,
      handlePrint,
    }
  },
}
</script>
<style scoped>
@media print {
  .page-break {
    display: block;
    page-break-before: always;
  }
}

#subscription-invoice-POS {
  box-shadow: 0 0 1in -0.25in rgba(0, 0, 0, 0.5);
  padding: 0 2mm;
  margin: 0 auto;
  width: 77mm;
  background: #FFF;
  font-family: 'arial', serif !important;
}

#subscription-invoice-POS ::selection {
  background: #f31544;
  color: #FFF;
}

#subscription-invoice-POS ::moz-selection {
  background: #f31544;
  color: #FFF;
}

#subscription-invoice-POS h1 {
  font-size: 1.5em;
  color: #222;
}

#subscription-invoice-POS h2 {
  font-size: 10px;
}

#subscription-invoice-POS h3 {
  font-size: 1.2em;
  font-weight: 300;
  line-height: 2em;
}

#subscription-invoice-POS p {
  font-size: 10px;
  font-weight: bold;
  color: #666;
  line-height: 1.2em;
}

#subscription-invoice-POS #top, #subscription-invoice-POS #mid, #subscription-invoice-POS #mid-1, #subscription-invoice-POS #mid-2, #subscription-invoice-POS #bot, #subscription-invoice-POS .table-header {
  border-bottom: 1px dashed #000;
  /*padding-top: 10px;*/
}

#subscription-invoice-POS #bot {
  padding-top: 0px !important;
}

#subscription-invoice-POS .balance-row { /* , #subscription-invoice-POS .table-header */
  border-top: 1px dashed #000;
}

#subscription-invoice-POS #top {
  min-height: 80px;
}

#subscription-invoice-POS #mid {
  min-height: 50px;
}

/*#subscription-invoice-POS #mid-1 {*/
/*  min-height: 30px;*/
/*}*/

#subscription-invoice-POS #bot {
  min-height: 50px;
}

#subscription-invoice-POS #bot h2 {
  font-size: 10px;
}

#subscription-invoice-POS #top .logo {
  height: 80px;
  width: 80px;
}

#subscription-invoice-POS .clientlogo {
  float: left;
  height: 60px;
  width: 60px;
  background: url(http://michaeltruong.ca/images/client.jpg) no-repeat;
  background-size: 60px 60px;
  border-radius: 50px;
}

#subscription-invoice-POS .info {
  display: block;
  margin-left: 0;
}

#subscription-invoice-POS .info-container {
  display: flex;
  justify-content: start;
  word-break: break-word;
}

#subscription-invoice-POS .info-container span:not(:last-child) {
  border-right: 1px solid #000;
  margin-right: 5px;
  padding-right: 5px;
}

#subscription-invoice-POS h1 {
  font-size: 1.5em;
  color: #000;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

#subscription-invoice-POS h2 {
  font-size: 10px;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

#subscription-invoice-POS h3 {
  font-size: 1.2em;
  font-weight: 300;
  line-height: 2em;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

#subscription-invoice-POS h5 {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

#subscription-invoice-POS .title {
  float: right;
}

#subscription-invoice-POS .title p {
  text-align: right;
}

#subscription-invoice-POS table {
  width: 100%;
  border-collapse: collapse;
}

#subscription-invoice-POS .tabletitle {
  font-size: 10px;
  font-weight: bold;
  margin: 3px;
  background: #EEE;
}

#subscription-invoice-POS .service {
  border-bottom: 1px solid #000;
  /*padding-top: 10px !important;*/
  /*padding-bottom: 10px !important;*/
}

#subscription-invoice-POS #mid-2 {
  /*padding-top: 10px !important;*/
}

#subscription-invoice-POS .item {
  /*width: 24mm;*/
}

#subscription-invoice-POS .tableitem {
  line-height: 0.8;
  padding-bottom: 5px;
}

#subscription-invoice-POS .itemtext {
  font-size: 10px;
  font-weight: bold;
  line-height: 1.6;
}

#subscription-invoice-POS .noteitem {
  line-height: 1.45;
}

#subscription-invoice-POS .notetext {
  margin-inline: .5rem;
}

#subscription-invoice-POS #legalcopy {
  font-size: 0.5em;
  margin-top: 5mm;
  color: #666;
}

#subscription-invoice-POS h2 {
  color: #666;
}

#subscription-invoice-POS span {
  color: #666;
}

#subscription-invoice-POS .font-size {
  font-size: 11px !important;
}

#subscription-invoice-POS .phone-box {
  flex-shrink: 0;
}
</style>
