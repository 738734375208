<template>
  <b-modal
    id="customer-details"
    title="Customer Details"
    title-class="text-black d-flex justify-content-center align-items-center"
    hide-footer
    no-close-on-backdrop
    size="xl"
    @shown="handleModalOpen"
    @hidden="handleModalClose"
  >
    <b-tabs>
      <b-tab
        active
        title="INFO"
        @click="orderActive = false; addressActive = false; transactionActive = false; subscriptionActive = false;"
      >
        <info-tab
          v-if="Object.keys(selectedCustomer).length > 0"
          :customer="selectedCustomer"
          @refreshCustomerDetails="getCustomer"
        />
      </b-tab>
      <b-tab
        title="PREFERENCES"
        @click="orderActive = false; addressActive = false; transactionActive = false; subscriptionActive = false;"
      >
        <preferences-tab
          v-if="Object.keys(selectedCustomer).length > 0"
          :customer-id="selectedCustomer.id"
        />
      </b-tab>
      <b-tab
        title="ORDERS"
        @click="orderActive = true; addressActive = false; transactionActive = false; subscriptionActive = false;"
      >
        <orders-tab
          v-if="Object.keys(selectedCustomer).length > 0 && orderActive"
          :customer-id="selectedCustomer.id"
        />
      </b-tab>
      <b-tab
        title="ADDRESSES"
        @click="addressActive = true; orderActive = false; transactionActive = false; subscriptionActive = false;"
      >
        <addresses-tab
          v-if="Object.keys(selectedCustomer).length > 0 && addressActive"
          :customer-id="selectedCustomer.id"
        />
      </b-tab>
      <b-tab
        title="TRANSACTIONS"
        @click="transactionActive = true; orderActive = false; addressActive = false; subscriptionActive = false;"
      >
        <transactions-tab
          v-if="Object.keys(selectedCustomer).length > 0 && transactionActive"
          :customer-id="selectedCustomer.id"
        />
      </b-tab>
      <b-tab
        title="SUBSCRIPTIONS"
        @click="subscriptionActive = true; transactionActive = false; orderActive = false; addressActive = false;"
      >
        <subscriptions-tab
          v-if="Object.keys(selectedCustomer).length > 0 && subscriptionActive"
          :customer-id="selectedCustomer.id"
        />
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
import { ref } from '@vue/composition-api'
import axios from 'axios'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BModal,
  VBToggle,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import InfoTab from './InfoTab.vue'
import OrdersTab from './OrdersTab.vue'
import AddressesTab from './AddressesTab.vue'
import TransactionsTab from './TransactionsTab.vue'
import PreferencesTab from './PreferencesTab.vue'
import SubscriptionsTab from './SubscriptionsTab.vue'

export default {
  components: {
    SubscriptionsTab,
    PreferencesTab,
    BModal,
    BTabs,
    BTab,
    InfoTab,
    OrdersTab,
    AddressesTab,
    TransactionsTab,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    customerId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const toast = useToast()
    const selectedCustomer = ref({})
    const orderActive = ref(false)
    const addressActive = ref(false)
    const transactionActive = ref(false)
    const subscriptionActive = ref(false)

    const getCustomer = () => {
      axios.get(`${process.env.VUE_APP_BASE_API_URL}/pos/users/${props.customerId}`, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      }).then(res => {
        if (res.data.code === 200) {
          selectedCustomer.value = res.data.data.user
        }
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching customer',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }

    const handleModalOpen = () => {
      getCustomer()
    }

    const handleModalClose = () => {
      selectedCustomer.value = {}
      orderActive.value = false
      addressActive.value = false
      transactionActive.value = false
    }

    return {
      handleModalOpen,
      handleModalClose,
      selectedCustomer,
      orderActive,
      addressActive,
      transactionActive,
      subscriptionActive,
      getCustomer,
    }
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
