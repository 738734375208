<template>
  <b-card
    no-body
  >
    <div>
      <div class="m-2">

        <!-- Table Top -->
        <b-row class="card-reverse">
          <b-col
            cols="12"
            xl="8"
            lg="12"
            md="12"
            sm="12"
            class="card-border-right mb-4"
          >
            <b-col
              cols="12"
              xl="12"
              lg="12"
              md="12"
              sm="12"
            >
              <b-row>
                <b-col
                  cols="12"
                  xl="6"
                  lg="12"
                  md="12"
                  sm="12"
                >
                  <b-form-group
                    label="Search For Items"
                    label-for="item-search"
                  >
                    <b-form-input
                      id="item-search"
                      v-model="itemSearch"
                      placeholder="Search by item name"
                      @input="handleCategoryFilterAndSearch"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  xl="4"
                  lg="12"
                  md="12"
                  sm="12"
                >
                  <b-form-group
                    label="Categories"
                    label-for="category"
                  >
                    <v-select
                      id="category"
                      v-model="category"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="categories"
                      :clearable="false"
                      label="name"
                      class="per-page-selector d-inline-block w-100 rounded"
                      placeholder="All"
                      :reduce="(option) => option.id"
                      @input="handleCategoryFilterAndSearch"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-button
                v-b-toggle.add-item-sidebar
                variant="flat-primary"
                class="mb-1"
                size="sm"
                @click="handleAddItemSidebarOpen"
              >
                <feather-icon
                  icon="PlusCircleIcon"
                />
                Add New Item
              </b-button>
              <b-row
                v-if="filteredItems.length > 0 && !loading"
                class="overflow-auto items-container"
              >
                <b-col
                  v-for="item in filteredItems"
                  :key="item.id"
                  cols="6"
                  xl="2"
                  lg="4"
                  md="2"
                  sm="4"
                  class="my-1"
                >
                  <div
                    v-b-toggle.item-sidebar
                    class="d-flex flex-column justify-content-center align-items-center text-center border rounded cursor-pointer item"
                    style="min-height: 135px"
                    @click="() => handleSelectedItem(item, 'add')"
                  >
                    <img
                      :src="item.image"
                      width="50"
                      height="50"
                      class="mt-1"
                    >
                    <span class="my-1">{{ item.name }}</span>
                  </div>
                </b-col>
              </b-row>
              <b-row
                v-else-if="loading"
              >
                <b-col
                  cols="12"
                >
                  <div
                    class="text-center p-4"
                  >
                    <b-spinner />
                  </div>
                </b-col>
              </b-row>
              <b-row
                v-else
              >
                <b-col
                  cols="12"
                >
                  <div
                    class="text-center p-4"
                  >
                    <h4>No Items</h4>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-col>
          <b-col
            cols="12"
            xl="4"
            lg="12"
            md="12"
            sm="12"
            class="d-flex flex-column justify-content-between mb-4"
          >
            <b-form>
              <div class="d-flex justify-content-between align-items-center">
                <h2>Cart</h2>
                <div>
                  <b-button
                    v-if="!orderId"
                    variant="flat-primary"
                    size="sm"
                    type="reset"
                    @click="handleResetCart"
                  >
                    <feather-icon
                      icon="TrashIcon"
                    />
                    Clear
                  </b-button>
                </div>
              </div>
              <!-- CUSTOMER -->
              <div
                v-if="!orderId"
                class="mt-3 d-flex justify-content-between align-items-center"
              >
                <b-form-group
                  label="Search For Customers"
                  label-for="customer-search"
                  class="flex-fill mx-1"
                >
                  <v-select
                    id="customer"
                    v-model="customer"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="customers"
                    :clearable="true"
                    label="phone"
                    class="per-page-selector d-inline-block w-100 rounded"
                    placeholder="Search For Customers"
                    @input="handleCustomerSelect"
                    @search="handleCustomerSearch"
                  />
                </b-form-group>
                <b-button
                  v-b-toggle.customer-add
                  variant="primary"
                  class="btn-icon mt-50"
                >
                  <feather-icon
                    icon="UserPlusIcon"
                  />
                </b-button>
              </div>
              <b-row
                v-if="selectedCustomer"
                class="p-1 rounded-pill align-items-center mx-1"
                :class="skin === 'dark' ? 'bg-github' : 'bg-light'"
              >
                <b-col
                  cols="12"
                  xl="5"
                  lg="5"
                  md="5"
                  sm="12"
                >
                  <img
                    src="@/assets/images/icons/user.png"
                    width="15"
                  >
                  <span class="">&nbsp;{{ selectedCustomer.full_name }}</span>
                </b-col>
                <b-col
                  v-if="!orderId"
                  cols="12"
                  xl="5"
                  lg="5"
                  md="5"
                  sm="12"
                >
                  <img
                    src="@/assets/images/icons/wallet.png"
                    width="15"
                  >
                  <span class="">&nbsp;{{ selectedCustomer.subscriptions_balance }} KWD</span>
                </b-col>
                <b-col
                  v-if="!orderId"
                  cols="12"
                  xl="2"
                  lg="2"
                  md="2"
                  sm="12"
                  class="d-flex justify-content-end"
                >
                  <b-button
                    variant="flat-danger"
                    class="btn-icon rounded-pill"
                    size="sm"
                    @click="handleCustomerRemove"
                  >
                    <feather-icon
                      icon="XIcon"
                      size="15"
                    />
                  </b-button>
                </b-col>
              </b-row>
              <!-- ORDER DETAILS -->
              <app-collapse
                id="order-info"
                type="margin"
                class="mt-2"
              >
                <!-- DATE & TIME -->
                <app-collapse-item
                  title="Date & Time"
                >
                  <template #img>
                    <img src="@/assets/images/icons/calendar.png">
                  </template>
                  <div class="my-1">
                    <!-- FAST -->
                    <div
                      v-if="expressToggle"
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div>
                        <img
                          src="@/assets/images/icons/timer.png"
                          width="24"
                        >
                        <span class="mx-1">Express</span>
                      </div>
                      <b-form-checkbox
                        v-model="hasFast"
                        class="custom-control-primary"
                        name="fast"
                        switch
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                    </div>
                    <!-- PICKUP -->
                    <div>
                      <div class="d-flex justify-content-between align-items-center my-1">
                        <div>
                          <img
                            src="@/assets/images/icons/responsible.png"
                            width="24"
                          >
                          <span class="mx-1">Pickup</span>
                        </div>
                        <b-form-checkbox
                          v-model="hasPickup"
                          class="custom-control-primary"
                          name="pickup"
                          switch
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                      </div>
                      <div
                        v-if="hasPickup"
                      >
                        <v-select
                          id="pickup-address"
                          v-model="pickupAddress"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="userAddresses"
                          :clearable="false"
                          class="per-page-selector mx-1 my-2"
                          placeholder="Pickup Address"
                          label="name"
                          :reduce="(option) => option.id"
                        />
                        <!-- <flat-pickr
                          v-model="pickupDate"
                          class="form-control"
                          :config="{ altInput: true,altFormat: 'd-m-Y', dateFormat: 'd-m-Y',}"
                          placeholder="Select Pickup Date"
                        /> -->
                        <div class="my-2 d-flex">
                          <v-select
                            id="pickup-day"
                            v-model="pickupDay"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="pickupWorkingDays"
                            :clearable="false"
                            class="per-page-selector mx-1"
                            style="width:65%"
                            placeholder="Pickup Day"
                            label="week_day"
                            :reduce="(option) => option.id"
                            @input="handlePickupDaySelect"
                          />
                          <v-select
                            id="pickup-time"
                            v-model="pickupTime"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="pickupWorkingHours"
                            :clearable="false"
                            class="per-page-selector w-100 mx-1"
                            placeholder="Pickup Time"
                            :reduce="(option) => option.id"
                          >
                            <template
                              slot="option"
                              slot-scope="option"
                            >
                              {{ option.start_at }} - {{ option.end_at }}
                            </template>
                            <template
                              slot="selected-option"
                              slot-scope="option"
                            >
                              {{ option.start_at }} - {{ option.end_at }}
                            </template>
                          </v-select>
                        </div>
                      </div>
                    </div>
                    <!-- DELIVERY -->
                    <div>
                      <div class="d-flex justify-content-between align-items-center">
                        <div>
                          <img
                            src="@/assets/images/icons/motorbike.png"
                            width="24"
                          >
                          <span class="mx-1">Delivery</span>
                        </div>
                        <b-form-checkbox
                          v-model="hasDelivery"
                          class="custom-control-primary"
                          name="delivery"
                          switch
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                      </div>
                      <div
                        v-if="hasDelivery"
                      >
                        <v-select
                          id="delivery-address"
                          v-model="deliveryAddress"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="userAddresses"
                          :clearable="false"
                          class="per-page-selector mx-1 my-2"
                          placeholder="Delivery Address"
                          label="name"
                          :reduce="(option) => option.id"
                        />
                        <!-- <flat-pickr
                          v-model="deliveryDate"
                          class="form-control"
                          :config="{ altInput: true,altFormat: 'd-m-Y', dateFormat: 'd-m-Y',}"
                          placeholder="Select Delivery Date"
                        /> -->
                        <div
                          v-if="!hasFast"
                          class="my-2 d-flex"
                        >
                          <v-select
                            id="delivery-day"
                            v-model="deliveryDay"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="deliveryWorkingDays"
                            :clearable="false"
                            class="per-page-selector mx-1"
                            style="width:65%"
                            placeholder="Delivery Day"
                            label="week_day"
                            :reduce="(option) => option.id"
                          />
                          <!-- @input="handleDeliveryDaySelect" -->
                          <v-select
                            id="delivery-time"
                            v-model="deliveryTime"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="deliveryWorkingHours"
                            :clearable="false"
                            class="per-page-selector w-100 mx-1"
                            placeholder="Delivery Time"
                            :reduce="(option) => option.id"
                          >
                            <template
                              slot="option"
                              slot-scope="option"
                            >
                              {{ option.start_at }} - {{ option.end_at }}
                            </template>
                            <template
                              slot="selected-option"
                              slot-scope="option"
                            >
                              {{ option.start_at }} - {{ option.end_at }}
                            </template>
                          </v-select>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="expressHours && hasFast"
                      class="text-success my-1"
                    >
                      Order will be finished within {{ expressHours }} hours
                    </div>
                    <!-- READY BY -->
                    <!-- <div v-if="!hasDelivery">
                      <div class="d-flex justify-content-between align-items-center my-1">
                        <div>
                          <img
                            src="@/assets/images/icons/ready.png"
                            width="24"
                          >
                          <span class="mx-1">Ready By</span>
                        </div>
                      </div>
                      <div class="my-2 d-flex">
                        <flat-pickr
                          v-model="readyByDate"
                          class="form-control"
                          :config="{ altInput: true,altFormat: 'd-m-Y', dateFormat: 'd-m-Y',}"
                          placeholder="Select Ready By Date"
                        />
                        <v-select
                          id="readyBy-time"
                          v-model="readyByTime"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="readyByTimes"
                          :clearable="false"
                          class="per-page-selector w-100 mx-1"
                          placeholder="Select Ready By Time"
                        />
                      </div>
                    </div> -->
                  </div>
                </app-collapse-item>
                <!--                <app-collapse-item-->
                <!--                  v-else-->
                <!--                  title="Addresses"-->
                <!--                >-->
                <!--                  <template #img>-->
                <!--                    <img src="@/assets/images/icons/address.png">-->
                <!--                  </template>-->
                <!--                  &lt;!&ndash; PICKUP &ndash;&gt;-->
                <!--                  <div>-->
                <!--                    <div-->
                <!--                      v-if="hasPickup"-->
                <!--                    >-->
                <!--                      <h6 class="d-flex align-items-center">-->
                <!--                        <img src="@/assets/images/icons/responsible.png">-->
                <!--                        <span class="mx-1">Pickup</span>-->
                <!--                      </h6>-->
                <!--                      <v-select-->
                <!--                        id="pickup-address"-->
                <!--                        v-model="pickupAddress"-->
                <!--                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
                <!--                        :options="userAddresses"-->
                <!--                        :clearable="false"-->
                <!--                        class="per-page-selector mx-1 my-2"-->
                <!--                        placeholder="Pickup Address"-->
                <!--                        label="name"-->
                <!--                        :reduce="(option) => option.id"-->
                <!--                      />-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                  &lt;!&ndash; DELIVERY &ndash;&gt;-->
                <!--                  <div>-->
                <!--                    <div-->
                <!--                      v-if="hasDelivery"-->
                <!--                    >-->
                <!--                      <h6 class="d-flex align-items-center">-->
                <!--                        <img src="@/assets/images/icons/motorbike.png">-->
                <!--                        <span class="mx-1">Delivery</span>-->
                <!--                      </h6>-->
                <!--                      <v-select-->
                <!--                        id="delivery-address"-->
                <!--                        v-model="deliveryAddress"-->
                <!--                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
                <!--                        :options="userAddresses"-->
                <!--                        :clearable="false"-->
                <!--                        class="per-page-selector mx-1 my-2"-->
                <!--                        placeholder="Delivery Address"-->
                <!--                        label="name"-->
                <!--                        :reduce="(option) => option.id"-->
                <!--                      />-->
                <!--                      <div-->
                <!--                        v-if="!hasFast"-->
                <!--                        class="my-2 d-flex"-->
                <!--                      >-->
                <!--                        <v-select-->
                <!--                          id="delivery-day"-->
                <!--                          v-model="deliveryDay"-->
                <!--                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
                <!--                          :options="deliveryWorkingDays"-->
                <!--                          :clearable="false"-->
                <!--                          class="per-page-selector mx-1"-->
                <!--                          style="width:65%"-->
                <!--                          placeholder="Delivery Day"-->
                <!--                          label="week_day"-->
                <!--                          :reduce="(option) => option.id"-->
                <!--                        />-->
                <!--                        &lt;!&ndash; @input="handleDeliveryDaySelect" &ndash;&gt;-->
                <!--                        <v-select-->
                <!--                          id="delivery-time"-->
                <!--                          v-model="deliveryTime"-->
                <!--                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
                <!--                          :options="deliveryWorkingHours"-->
                <!--                          :clearable="false"-->
                <!--                          class="per-page-selector w-100 mx-1"-->
                <!--                          placeholder="Delivery Time"-->
                <!--                          :reduce="(option) => option.id"-->
                <!--                        >-->
                <!--                          <template-->
                <!--                            slot="option"-->
                <!--                            slot-scope="option"-->
                <!--                          >-->
                <!--                            {{ option.start_at }} - {{ option.end_at }}-->
                <!--                          </template>-->
                <!--                          <template-->
                <!--                            slot="selected-option"-->
                <!--                            slot-scope="option"-->
                <!--                          >-->
                <!--                            {{ option.start_at }} - {{ option.end_at }}-->
                <!--                          </template>-->
                <!--                        </v-select>-->
                <!--                      </div>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </app-collapse-item>-->
                <!-- ITEMS -->
                <app-collapse-item
                  title="Items"
                >
                  <template #img>
                    <img src="@/assets/images/icons/laundry-basket.png">
                  </template>
                  <div v-if="dryCleanItems.length || washIronItems.length || ironItems.length || dryItems.length">
                    <div v-if="dryCleanItems.length">
                      <div class="my-2">
                        <span
                          class="bg-primary bg-lighten-5 p-50 rounded"
                          :class="skin === 'dark' ? 'text-black' : ''"
                        >
                          Dry Clean
                        </span>
                      </div>
                      <b-row
                        v-for="(item, index) in dryCleanItems"
                        :key="index"
                        v-b-toggle.item-sidebar
                        class="d-flex align-items-center cursor-pointer"
                        :class="skin === 'dark' ? '' : 'box-hover'"
                        @click="() => item.type === 'custom' ? handleSelectedItem(item, 'custom_edit') : handleSelectedItem(item, 'edit')"
                      >
                        <b-col
                          cols="12"
                          xl="7"
                          lg="12"
                          class="my-1"
                        >
                          <span :class="skin === 'dark' ? 'text-white' : 'text-black'">{{ item.name }} <span class="small text-dark">({{ item.parent.name }})</span></span>
                        </b-col>
                        <b-col
                          cols="12"
                          xl="2"
                          lg="12"
                        >
                          <span
                            class="font-weight-bold"
                            :class="skin === 'dark' ? 'text-white' : 'text-black'"
                          >
                            x{{ item.quantity }}
                          </span>
                        </b-col>
                        <b-col
                          cols="12"
                          xl="3"
                          lg="12"
                        >
                          <span
                            class="font-weight-bold"
                            :class="skin === 'dark' ? 'text-white' : 'text-black'"
                          >
                            {{ item.item_price }} KWD
                          </span>
                        </b-col>
                        <span
                          v-if="item.type === 'custom'"
                          class="custom-label-success p-25 rounded-pill btn-sm mx-25"
                        >
                          CUSTOM
                        </span>
                      </b-row>
                    </div>
                    <div v-if="washIronItems.length">
                      <div class="my-2">
                        <span
                          class="bg-primary bg-lighten-5 p-50 rounded"
                          :class="skin === 'dark' ? 'text-black' : ''"
                        >
                          Wash+Iron
                        </span>
                      </div>
                      <b-row
                        v-for="(item, index) in washIronItems"
                        :key="index"
                        v-b-toggle.item-sidebar
                        class="d-flex align-items-center cursor-pointer"
                        :class="skin === 'dark' ? '' : 'box-hover'"
                        @click="() => item.type === 'custom' ? handleSelectedItem(item, 'custom_edit') : handleSelectedItem(item, 'edit')"
                      >
                        <b-col
                          cols="12"
                          xl="7"
                          lg="12"
                          class="my-1"
                        >
                          <span :class="skin === 'dark' ? 'text-white' : 'text-black'">{{ item.name }} <span class="small text-dark">({{ item.parent.name }})</span></span>
                        </b-col>
                        <b-col
                          cols="12"
                          xl="2"
                          lg="12"
                        >
                          <span
                            class="font-weight-bold"
                            :class="skin === 'dark' ? 'text-white' : 'text-black'"
                          >
                            x{{ item.quantity }}
                          </span>
                        </b-col>
                        <b-col
                          cols="12"
                          xl="3"
                          lg="12"
                        >
                          <span
                            class="font-weight-bold"
                            :class="skin === 'dark' ? 'text-white' : 'text-black'"
                          >
                            {{ item.item_price }} KWD
                          </span>
                        </b-col>
                        <span
                          v-if="item.type === 'custom'"
                          class="custom-label-success p-25 rounded-pill btn-sm mx-25"
                        >
                          CUSTOM
                        </span>
                      </b-row>
                    </div>
                    <div v-if="ironItems.length">
                      <div class="my-2">
                        <span
                          class="bg-primary bg-lighten-5 p-50 rounded"
                          :class="skin === 'dark' ? 'text-black' : ''"
                        >
                          Iron
                        </span>
                      </div>
                      <b-row
                        v-for="(item, index) in ironItems"
                        :key="index"
                        v-b-toggle.item-sidebar
                        class="d-flex align-items-center cursor-pointer"
                        :class="skin === 'dark' ? '' : 'box-hover'"
                        @click="() => item.type === 'custom' ? handleSelectedItem(item, 'custom_edit') : handleSelectedItem(item, 'edit')"
                      >
                        <b-col
                          cols="12"
                          xl="7"
                          lg="12"
                          class="my-1"
                        >
                          <span :class="skin === 'dark' ? 'text-white' : 'text-black'">{{ item.name }} <span class="small text-dark">({{ item.parent.name }})</span></span>
                        </b-col>
                        <b-col
                          cols="12"
                          xl="2"
                          lg="12"
                        >
                          <span
                            class="font-weight-bold"
                            :class="skin === 'dark' ? 'text-white' : 'text-black'"
                          >
                            x{{ item.quantity }}
                          </span>
                        </b-col>
                        <b-col
                          cols="12"
                          xl="3"
                          lg="12"
                        >
                          <span
                            class="font-weight-bold"
                            :class="skin === 'dark' ? 'text-white' : 'text-black'"
                          >
                            {{ item.item_price }} KWD
                          </span>
                        </b-col>
                        <span
                          v-if="item.type === 'custom'"
                          class="custom-label-success p-25 rounded-pill btn-sm mx-25"
                        >
                          CUSTOM
                        </span>
                      </b-row>
                    </div>
                    <div v-if="dryItems.length">
                      <div class="my-2">
                        <span
                          class="bg-primary bg-lighten-5 p-50 rounded"
                          :class="skin === 'dark' ? 'text-black' : ''"
                        >
                          Dry
                        </span>
                      </div>
                      <b-row
                        v-for="(item, index) in dryItems"
                        :key="index"
                        v-b-toggle.item-sidebar
                        class="d-flex align-items-center cursor-pointer"
                        :class="skin === 'dark' ? '' : 'box-hover'"
                        @click="() => item.type === 'custom' ? handleSelectedItem(item, 'custom_edit') : handleSelectedItem(item, 'edit')"
                      >
                        <b-col
                          cols="12"
                          xl="7"
                          lg="12"
                          class="my-1"
                        >
                          <span :class="skin === 'dark' ? 'text-white' : 'text-black'">{{ item.name }} <span class="small text-dark">({{ item.parent.name }})</span></span>
                        </b-col>
                        <b-col
                          cols="12"
                          xl="2"
                          lg="12"
                        >
                          <span
                            class="font-weight-bold"
                            :class="skin === 'dark' ? 'text-white' : 'text-black'"
                          >
                            x{{ item.quantity }}
                          </span>
                        </b-col>
                        <b-col
                          cols="12"
                          xl="3"
                          lg="12"
                        >
                          <span
                            class="font-weight-bold"
                            :class="skin === 'dark' ? 'text-white' : 'text-black'"
                          >
                            {{ item.item_price }} KWD
                          </span>
                        </b-col>
                        <span
                          v-if="item.type === 'custom'"
                          class="custom-label-success p-25 rounded-pill btn-sm mx-25"
                        >
                          CUSTOM
                        </span>
                      </b-row>
                    </div>
                  </div>
                  <div
                    v-else
                    class="d-flex flex-column justify-content-center align-items-center"
                  >
                    <img src="@/assets/images/icons/laundry-basket2.png">
                    <span class="my-2">Entered items will start to show here</span>
                  </div>
                  <div class="my-1 d-flex justify-content-between align-items-center">
                    <b-button
                      v-b-toggle.general-instructions
                      variant="flat-primary"
                      size="sm"
                    >
                      <img
                        src="@/assets/images/icons/note.png"
                        width="28"
                      >
                      <span>General Instructions</span>
                    </b-button>
                    <b-button
                      v-if="$store.getters['cart/getGeneralInstructions']"
                      variant="flat-danger"
                      size="sm"
                      class="btn-icon"
                      @click="handleDeleteGeneralInstructions"
                    >
                      <feather-icon
                        icon="TrashIcon"
                      />
                    </b-button>
                  </div>
                  <span v-if="generalInstructions">{{ generalInstructions }}</span>
                </app-collapse-item>
                <!-- RECEIPT -->
                <app-collapse-item
                  title="Receipt"
                >
                  <template #img>
                    <img src="@/assets/images/icons/bill2.png">
                  </template>
                  <div class="d-flex justify-content-between align-items-center text-black my-1">
                    <span :class="skin === 'dark' ? 'text-white' : 'text-black'">Subtotal</span>
                    <span
                      class="font-weight-bold"
                      :class="skin === 'dark' ? 'text-white' : 'text-black'"
                    >
                      <span class="small text-dark">({{ totalItems }} {{ totalItems === 1 ? 'item' : 'items' }})</span> {{ +(subTotal.toFixed(2)) }} KWD
                    </span>
                  </div>
                  <div>
                    <div class="d-flex justify-content-between">
                      <input
                        v-model="promoCode"
                        type="text"
                        class="form-control w-50"
                        placeholder="Promo Code"
                        :disabled="promoCodeInputDisabled"
                        @input="handlePromoCodeChange"
                      >
                      <b-button
                        v-if="promoCodeInputDisabled"
                        variant="flat-danger"
                        size="sm"
                        @click="handlePromoCodeRemove"
                      >
                        <!--                        <img-->
                        <!--                          src="@/assets/images/icons/discount1.png"-->
                        <!--                          width="20"-->
                        <!--                        >-->
                        <span>Remove</span>
                      </b-button>
                      <!--                      <b-button-->
                      <!--                        variant="flat-primary"-->
                      <!--                        size="sm"-->
                      <!--                        @click="handlePromoCodeCheck"-->
                      <!--                      >-->
                      <!--                        <img-->
                      <!--                          src="@/assets/images/icons/discount1.png"-->
                      <!--                          width="20"-->
                      <!--                        >-->
                      <!--                        <span> Apply Discount</span>-->
                      <!--                      </b-button>-->
                    </div>
                    <div class="mt-2">
                      <p>Promo Discount: {{ promoCodeAmount ? promoCodeAmount : '0 %' }}</p>
                      <p>User Discount: {{ userDiscount ? userDiscount : 0 }} %</p>
                      <p class="text-success">
                        {{ promoCodeMessage }}
                      </p>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-center text-black mt-2">
                    <span
                      class="font-weight-bold"
                      :class="skin === 'dark' ? 'text-white' : 'text-black'"
                    >
                      Total
                    </span>
                    <span
                      class="text-primary font-medium-3 font-weight-bolder"
                      :class="skin === 'dark' ? 'text-white' : 'text-darken-2'"
                    >
                      {{ +(grandTotal.toFixed(2)) }} KWD
                    </span>
                  </div>
                  <span
                    v-if="hasFast"
                    class="small text-danger"
                  >
                    Subtotal * {{ expressCost }} (Express Cost)
                  </span>
                </app-collapse-item>
              </app-collapse>
              <div class="mt-3 text-danger">
                {{ error }}
              </div>
              <div class="mt-3 d-flex">
                <b-button
                  v-if="orderId"
                  variant="primary"
                  class="mx-3 flex-fill"
                  :disabled="submitButtonDisabled"
                  @click="handleEditOrder"
                >
                  <span class="align-middle">Edit Order</span>
                </b-button>
                <b-button
                  v-else
                  variant="primary"
                  class="mx-3 flex-fill"
                  :disabled="submitButtonDisabled"
                  @click="handleCreateOrder"
                >
                  <span class="align-middle">Create Order</span>
                </b-button>
              </div>
            </b-form>
          </b-col>
        </b-row>

      </div>
    </div>
    <div v-if="Object.keys(selectedItem).length > 0">
      <right-sidebar
        id="item-sidebar"
        :title="itemSidebarType == 'edit' ? 'Edit Item' : 'Add Item'"
        :hidden="handleSidebarClose"
      >
        <!-- <custom-item-sidebar
          v-if="itemSidebarType == 'custom' || itemSidebarType == 'custom_edit'"
          :type="itemSidebarType"
        /> -->
        <item-sidebar
          :item="selectedItem"
          :type="itemSidebarType"
        />
      </right-sidebar>

    </div>
    <div v-if="addItemOpened">
      <right-sidebar
        id="add-item-sidebar"
        title="New Item"
        :hidden="handleAddItemSidebarClose"
      >
        <create-pricelist @refreshPriceList="refreshPriceList" />
      </right-sidebar>

    </div>
    <right-sidebar
      id="general-instructions"
      title="Add General Instructions"
    >
      <general-instructions @changeGeneralInstructions="handleChangeGeneralInstructions" />
    </right-sidebar>

    <right-sidebar
      id="customer-add"
      title="Add Customer"
    >
      <customer-form
        type="add"
      />
    </right-sidebar>
  </b-card>
</template>

<script>
import {
  computed,
  onMounted,
  ref,
  watch,
} from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BButton,
  VBToggle,
  BFormGroup,
  BFormInput,
  BForm,
  BFormCheckbox,
  BSpinner,
  // BFormDatepicker,
} from 'bootstrap-vue'
import useAppConfig from '@core/app-config/useAppConfig'
// import { useRoute } from 'vue-router'
// import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import axios from 'axios'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import RightSidebar from '../sidebar/RightSidebar.vue'
import store from '@/store'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import party from 'party-js'
import CreatePricelist from '@/layouts/components/pricelist/CreatePricelist.vue'
import useOrders from '../useOrders'
import RightSidebar from '../../sidebar/RightSidebar.vue'
import ItemSidebar from './ItemSidebar.vue'
import GeneralInstructions from './GeneralInstructions.vue'
import CustomerForm from '../../customers/CustomerForm.vue'

export default {
  components: {
    CreatePricelist,
    BCard,
    BRow,
    BCol,
    BButton,
    // RightSidebar,
    vSelect,
    AppCollapseItem,
    AppCollapse,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    // BFormDatepicker,
    // flatPickr,
    RightSidebar,
    ItemSidebar,
    GeneralInstructions,
    CustomerForm,
    BSpinner,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  setup(props, context) {
    const { customerId } = context.root.$route.params
    const orderId = context.root.$route.params.id
    const { skin } = useAppConfig()

    const toast = useToast()
    const itemSidebarType = ref('add')

    const itemSearch = ref('')
    const category = ref(0)
    const categories = ref([
      {
        id: 0,
        name: 'All',
      },
    ])
    const error = ref('')
    const allItems = ref([])
    const filteredItems = ref([])
    const selectedItem = ref({})
    const customers = ref([])
    const customer = ref('')
    const selectedCustomer = ref('')
    const hasFast = ref(false)
    const hasPickup = ref(true)
    const hasDelivery = ref(true)
    const pickupDay = ref('')
    const deliveryDay = ref('')
    const pickupTime = ref('')
    const deliveryTime = ref('')
    const promoCode = ref('')
    const promoCodeInputDisabled = ref(false)
    const promoCodeTimer = ref(null)
    const promoCodeAmount = ref('')
    const promoCodeMessage = ref('')
    const userDiscount = ref('')
    const grandTotal = ref(0)
    const expressToggle = ref(1)
    const expressCost = ref(1)
    const expressHours = ref('')
    const generalInstructions = ref('')
    const submitButtonDisabled = ref(false)
    const loading = ref(true)
    const customerSearchTimer = ref(null)
    const addItemOpened = ref(false)
    const totalItems = computed(() => {
      let dryCleanTotalItems = 0
      let washIronTotalItems = 0
      let ironTotalItems = 0
      let dryTotalItems = 0
      store.getters['cart/getDryCleanItems'].forEach(item => {
        dryCleanTotalItems += +item.quantity
      })
      store.getters['cart/getWashIronItems'].forEach(item => {
        washIronTotalItems += +item.quantity
      })
      store.getters['cart/getIronItems'].forEach(item => {
        ironTotalItems += +item.quantity
      })
      store.getters['cart/getDryItems'].forEach(item => {
        dryTotalItems += +item.quantity
      })

      return dryCleanTotalItems + washIronTotalItems + ironTotalItems + dryTotalItems
    })
    const subTotal = computed(() => {
      let dryCleanSubTotal = 0
      let washIronSubTotal = 0
      let ironSubTotal = 0
      let drySubTotal = 0
      store.getters['cart/getDryCleanItems'].forEach(item => {
        dryCleanSubTotal += item.total_price
      })
      store.getters['cart/getWashIronItems'].forEach(item => {
        washIronSubTotal += item.total_price
      })
      store.getters['cart/getIronItems'].forEach(item => {
        ironSubTotal += item.total_price
      })
      store.getters['cart/getDryItems'].forEach(item => {
        drySubTotal += item.total_price
      })

      return dryCleanSubTotal + washIronSubTotal + ironSubTotal + drySubTotal
    })
    const total = computed(() => {
      let dryCleanTotal = 0
      let washIronTotal = 0
      let ironTotal = 0
      let dryTotal = 0
      store.getters['cart/getDryCleanItems'].forEach(item => {
        dryCleanTotal += item.total_price
      })
      store.getters['cart/getWashIronItems'].forEach(item => {
        washIronTotal += item.total_price
      })
      store.getters['cart/getIronItems'].forEach(item => {
        ironTotal += item.total_price
      })
      store.getters['cart/getDryItems'].forEach(item => {
        dryTotal += item.total_price
      })
      if (hasFast.value) {
        return (dryCleanTotal + washIronTotal + ironTotal + dryTotal) * expressCost.value
      }
      return dryCleanTotal + washIronTotal + ironTotal + dryTotal
    })

    const workingDays = ref([])
    const pickupWorkingDays = ref([])
    const deliveryWorkingDays = ref([])
    const pickupWorkingHours = computed(() => pickupWorkingDays.value.filter(day => day.id === pickupDay.value)[0]?.working_hours)
    const deliveryWorkingHours = computed(() => deliveryWorkingDays.value.filter(day => day.id === deliveryDay.value)[0]?.working_hours)
    const dryCleanItems = computed(() => store.getters['cart/getDryCleanItems'])
    const washIronItems = computed(() => store.getters['cart/getWashIronItems'])
    const ironItems = computed(() => store.getters['cart/getIronItems'])
    const dryItems = computed(() => store.getters['cart/getDryItems'])

    const pickupAddress = ref('')
    const deliveryAddress = ref('')
    const userAddresses = ref([])

    const {
      fetchItems,
      fetchOrder,
      fetchCategories,
      fetchCustomers,
      fetchWorkingDays,
      fetchUserAddresses,
      fetchExpressConfig,
      fetchDeliveryDays,
    } = useOrders()

    watch([pickupDay], () => {
      // pickupTime.value = ''
      if (pickupDay.value) {
        const selectedPickup = workingDays.value.filter(day => day.id === pickupDay.value)[0]
        if (selectedPickup) {
          fetchDeliveryDays(hasFast.value ? 2 : 1, selectedPickup?.working_hours[0]?.id).then(res => {
            deliveryWorkingDays.value = res
            setTimeout(() => {
              pickupTime.value = selectedPickup?.working_hours[0]?.id
            }, 50)
          })
          // deliveryWorkingDays.value = workingDays.value.filter(day => day.week_day > selectedPickup.week_day)
        }
      } else {
        fetchDeliveryDays(hasFast.value ? 2 : 1, workingDays.value[0]?.working_hours[0]?.id).then(res => {
          deliveryWorkingDays.value = res
        })
        // deliveryWorkingDays.value = workingDays.value.filter(day => day.week_day > new Date().toJSON().slice(0, 10))
      }
    })

    watch([deliveryDay], () => {
      deliveryTime.value = ''
    })

    watch([hasPickup], () => {
      // eslint-disable-next-line no-nested-ternary
      const type = !hasPickup.value ? 2 : hasFast.value ? 2 : 1
      if (!hasPickup.value) {
        fetchDeliveryDays(type, workingDays.value[0]?.working_hours[0]?.id).then(result => {
          deliveryWorkingDays.value = result
        })
      }
    })

    // watch([deliveryWorkingDays], () => {
    //   deliveryDay.value = deliveryWorkingDays.value[0]?.id
    //   setTimeout(() => {
    //     deliveryTime.value = deliveryWorkingDays.value[0]?.working_hours[0]?.id
    //   }, 50)
    // })

    watch([pickupTime], () => {
      if (hasDelivery.value) {
        if (pickupWorkingHours.value) {
          const pickupTimeObj = pickupWorkingHours.value.filter(time => time.id === pickupTime.value)[0]
          const pickupDayObj = pickupWorkingDays.value.filter(day => day.id === pickupDay.value)[0]
          if (pickupTimeObj) {
            const deliveryDayArr = deliveryWorkingDays.value.filter(day => day.week_day > pickupDayObj.week_day)
            if (deliveryDayArr.length > 0) {
              deliveryDay.value = deliveryDayArr[0].id
              const deliveryTimeArr = deliveryWorkingHours.value.filter(time => time.start_at === pickupTimeObj.start_at)
              if (deliveryTimeArr.length > 0) {
                setTimeout(() => {
                  deliveryTime.value = deliveryTimeArr[0].id
                }, 50)
              }
            }
          }
        }
      }
    })

    watch([hasFast], () => {
      deliveryDay.value = ''
      if (pickupDay.value) {
        const selectedPickup = workingDays.value.filter(day => day.id === pickupDay.value)[0]
        if (selectedPickup) {
          fetchDeliveryDays(hasFast.value ? 2 : 1, selectedPickup?.working_hours[0]?.id).then(res => {
            deliveryWorkingDays.value = res
          })
          // deliveryWorkingDays.value = workingDays.value.filter(day => day.week_day > selectedPickup.week_day)
        }
      } else if (workingDays.value.length > 0) {
        fetchDeliveryDays(hasFast.value ? 2 : 1, workingDays.value[0]?.working_hours[0]?.id).then(res => {
          deliveryWorkingDays.value = res
        })
        // deliveryWorkingDays.value = workingDays.value.filter(day => day.week_day > new Date().toJSON().slice(0, 10))
      }
    }, {
      immediate: true,
    })

    // watch([hasFast, total], () => {
    //   if (hasFast.value) {
    //     grandTotal.value = total.value * expressCost.value
    //   } else {
    //     grandTotal.value = total.value
    //   }
    // }, {
    //   immediate: true,
    // })

    onMounted(() => {
      // eslint-disable-next-line no-use-before-define
      // handleResetCart()
      store.commit('cart/RESET_CART')

      fetchCategories().then(categoryRes => {
        categories.value = categories.value.concat(categoryRes)

        fetchItems().then(result => {
          allItems.value = result
          filteredItems.value = result
          loading.value = false

          if (orderId) {
            fetchOrder(orderId).then(res => {
              customer.value = res.user
              fetchUserAddresses(customer.value.id).then(addressRes => {
                userAddresses.value = addressRes
              })
              selectedCustomer.value = res.user
              pickupAddress.value = res.pickup_address ? res.pickup_address.id : ''
              deliveryAddress.value = res.delivery_address ? res.delivery_address.id : ''
              promoCode.value = res.promo_code ? res.promo_code.code : ''
              hasFast.value = res.type !== 'Standard'
              hasPickup.value = !!res.pickup_time
              hasDelivery.value = !!res.delivery_address
              pickupDay.value = res.pickup_time ? res.pickup_time.day.id : ''
              deliveryDay.value = res.delivery_time ? res.delivery_time.day.id : ''
              generalInstructions.value = res.user_note
              setTimeout(() => {
                pickupTime.value = res.pickup_time ? res.pickup_time.id : ''
                deliveryTime.value = res.delivery_time ? res.delivery_time.id : ''
              }, 50)
              userDiscount.value = res.user_discount

              // expressToggle.value = +res.active_express_service
              if (res.type !== 'Standard') {
                expressCost.value = +res.express_cost
              } else {
                fetchExpressConfig().then(result => {
                  expressToggle.value = +result.active_express_service
                  expressCost.value = +result.express_service_price
                  expressHours.value = result.delivery_hours
                })
              }
              // expressHours.value = res.delivery_hours

              res.items.forEach((item, index) => {
                const cartItem = { ...allItems.value.filter(filteredItem => filteredItem.id === item.id)[0] }

                if (Object.keys(cartItem).length > 0) {
                  // console.log(cartItem)
                  // eslint-disable-next-line prefer-destructuring
                  // cartItem.piece_type = cartItem.sub_categories.filter(sub => sub.id === item.id)[0]
                  cartItem.quantity = item.quantity
                  cartItem.selected_service = item.service_type.toLowerCase().replaceAll(' ', '_').replaceAll('and_', '')
                  cartItem.notes = item.note
                  cartItem.type = 'normal'
                  cartItem.item_price = cartItem.parent.price + +cartItem[`price_${cartItem.selected_service}`]
                  cartItem.total_price = cartItem.item_price * item.quantity
                  cartItem.cart_id = `${cartItem.id}-${cartItem.selected_service}`
                  // cartItem.cart_id = index + 1
                  store.commit('cart/ADD_TO_CART', cartItem)
                }
              })

              const remainingData = {
                customer: res.user,
                pickupAddress: res.pickup_address ? res.pickup_address.id : '',
                deliveryAddress: res.delivery_address ? res.delivery_address.id : '',
                promoCode: res.promo_code ? res.promo_code.code : '',
                hasFast: res.type !== 'Standard',
                hasPickup: !!res.pickup_time,
                hasDelivery: !!res.delivery_address,
                pickupDay: res.pickup_time ? res.pickup_time.day.id : '',
                deliveryDay: res.delivery_time ? res.delivery_time.day.id : '',
                pickupTime: res.pickup_time ? res.pickup_time.id : '',
                deliveryTime: res.delivery_time ? res.delivery_time.id : '',
                generalInstructions: res.user_note,
              }
              store.commit('cart/ADD_REMAINING_DATA', remainingData)
            })
          }
        })
      })

      fetchCustomers(customerId).then(res => {
        customers.value = res
        if (customerId) {
          // eslint-disable-next-line prefer-destructuring
          customer.value = customers.value.filter(cu => cu.id === +customerId)[0]
          selectedCustomer.value = customer.value
          userDiscount.value = customer.value.order_discount
          pickupAddress.value = ''
          deliveryAddress.value = ''
          fetchUserAddresses(customer.value.id).then(result => {
            userAddresses.value = result
            const primaryAddress = result.filter(address => address.primary)[0]
            if (primaryAddress) {
              pickupAddress.value = primaryAddress.id
              deliveryAddress.value = primaryAddress.id
            }
          })
        }
      })

      fetchWorkingDays().then(res => {
        workingDays.value = res
        pickupWorkingDays.value = res
        // deliveryWorkingDays.value = res.filter(day => day.week_day > new Date().toJSON().slice(0, 10))
        // eslint-disable-next-line no-nested-ternary
        // const type = !hasPickup.value ? 2 : hasFast.value ? 2 : 1
        // fetchDeliveryDays(type, workingDays.value[0]?.working_hours[0]?.id).then(result => {
        //   deliveryWorkingDays.value = result
        // })
        pickupDay.value = pickupWorkingDays.value[0]?.id
      })
      if (!orderId) {
        fetchExpressConfig().then(res => {
          expressToggle.value = +res.active_express_service
          expressCost.value = +res.express_service_price
          expressHours.value = res.delivery_hours
        })
      }
    })

    const handleCustomerSearch = searchTerm => {
      clearTimeout(customerSearchTimer.value)
      customerSearchTimer.value = setTimeout(() => {
        fetchCustomers(searchTerm).then(res => {
          customers.value = res
        })
      }, 500)
    }

    const handleCustomerSelect = () => {
      if (customer.value) {
        selectedCustomer.value = customer.value
        userDiscount.value = customer.value.order_discount
        pickupAddress.value = ''
        deliveryAddress.value = ''
        fetchUserAddresses(customer.value.id).then(res => {
          userAddresses.value = res
          const primaryAddress = res.filter(address => address.primary)[0]
          if (primaryAddress) {
            pickupAddress.value = primaryAddress.id
            deliveryAddress.value = primaryAddress.id
          }
        })
      }
    }

    const handleCustomerRemove = () => {
      customer.value = ''
      selectedCustomer.value = ''
      userDiscount.value = ''
      pickupAddress.value = ''
      deliveryAddress.value = ''
      userAddresses.value = []
    }

    const handlePickupDaySelect = () => {
      const selectedPickup = workingDays.value.filter(day => day.id === pickupDay.value)[0]
      if (selectedPickup.reach_limit) {
        error.value = 'Maximum limit of orders reached for the selected day'
        submitButtonDisabled.value = true
      } else {
        error.value = ''
        submitButtonDisabled.value = false
        deliveryDay.value = ''
        fetchDeliveryDays(hasFast.value ? 2 : 1, selectedPickup.working_hours[0]?.id).then(result => {
          deliveryWorkingDays.value = result
        })
        // if (!hasFast.value) {
        //   deliveryWorkingDays.value = workingDays.value.filter(day => day.week_day > selectedPickup.week_day)
        // } else {
        //   deliveryWorkingDays.value = workingDays.value.filter(day => day.week_day >= selectedPickup.week_day)
        // }
      }
    }

    // const handleDeliveryDaySelect = () => {
    //   if (deliveryDay.value.reach_limit) {
    //     error.value = 'Maximum limit of orders reached for the selected delivery day'
    //   } else {
    //     error.value = ''
    //   }
    // }

    const handleSidebarClose = () => {
      selectedItem.value = {}
    }

    const handleAddItemSidebarClose = () => {
      addItemOpened.value = false
    }

    const refreshPriceList = (parentId, item) => {
      if (parentId) {
        allItems.value = [...allItems.value, item]
      } else {
        categories.value = [...categories.value, item]
      }
    }

    const handleSelectedItem = (item, type) => {
      selectedItem.value = item
      itemSidebarType.value = type
    }

    const handleAddItemSidebarOpen = () => {
      addItemOpened.value = true
    }

    const handleCategoryFilter = () => {
      if (category.value === 0) {
        filteredItems.value = allItems.value
      } else {
        filteredItems.value = allItems.value.filter(item => item.parent.id === category.value)
      }
    }

    const handleCategoryFilterAndSearch = () => {
      handleCategoryFilter()
      if (itemSearch.value !== '') {
        filteredItems.value = filteredItems.value.filter(item => item.name.toLowerCase().includes(itemSearch.value.toLowerCase()))
      }
    }

    const handleChangeGeneralInstructions = instructions => {
      generalInstructions.value = instructions
    }

    const handlePromoCodeCheck = () => {
      if (promoCode.value) {
        const data = {
          promo_code: promoCode.value,
          payment_method_id: 1,
          type: '3',
        }
        axios.post(`${process.env.VUE_APP_BASE_API_URL}/user/check-promo-code`, data, {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        }).then(res => {
          if (res.data.code === 200) {
            error.value = ''
            submitButtonDisabled.value = false

            const code = res.data.data
            let promoCodeTotal = 0
            let userDiscountTotal = 0

            if (code) {
              store.commit('cart/ADD_PROMO_CODE', promoCode.value)

              if (code.promo_type === 1) {
                promoCodeTotal = total.value - code.amount
                promoCodeAmount.value = `${code.amount} KWD`
              } else {
                promoCodeTotal = total.value * (1 - (code.amount / 100))
                promoCodeAmount.value = `${code.amount} %`
              }
            }

            if (userDiscount.value) {
              userDiscountTotal = total.value * (1 - (userDiscount.value / 100))
            }

            if (promoCodeTotal && userDiscountTotal) {
              grandTotal.value = Math.min(promoCodeTotal, userDiscountTotal)
            } else if (promoCodeTotal && !userDiscountTotal) {
              grandTotal.value = promoCodeTotal
            } else if (!promoCodeTotal && userDiscountTotal) {
              grandTotal.value = userDiscountTotal
            }
            promoCodeInputDisabled.value = true
            promoCodeMessage.value = 'Better discount is applied'
          }
        }).catch(e => {
          error.value = e.response.data.message
          submitButtonDisabled.value = true
        })
      } else if (userDiscount.value) {
        grandTotal.value = total.value * (1 - (userDiscount.value / 100))
        promoCodeMessage.value = 'Better discount is applied'
      } else {
        error.value = ''
        submitButtonDisabled.value = false
        promoCodeInputDisabled.value = false
        promoCodeAmount.value = ''
        promoCodeMessage.value = ''
        grandTotal.value = total.value
      }
    }

    watch([total], () => {
      grandTotal.value = total.value
      handlePromoCodeCheck()
    }, {
      immediate: true,
    })

    const handlePromoCodeChange = () => {
      // if (promoCode.value.length >= 2) {
      clearTimeout(promoCodeTimer.value)
      promoCodeTimer.value = setTimeout(() => {
        handlePromoCodeCheck()
      }, 500)
      // }
      // if (!promoCode.value) {
      //   submitButtonDisabled.value = false
      // }
    }

    const handlePromoCodeRemove = () => {
      promoCode.value = ''
      handlePromoCodeCheck()
    }

    const handleDeleteGeneralInstructions = () => {
      store.commit('cart/REMOVE_GENERAL_INSTRUCTIONS')
    }

    const handleResetCart = () => {
      customer.value = ''
      selectedCustomer.value = ''
      pickupAddress.value = ''
      deliveryAddress.value = ''
      promoCode.value = ''
      promoCodeAmount.value = ''
      promoCodeMessage.value = ''
      hasFast.value = false
      hasPickup.value = false
      hasDelivery.value = false
      pickupDay.value = ''
      deliveryDay.value = ''
      pickupTime.value = ''
      deliveryTime.value = ''
      // readyByTime.value = ''
      store.commit('cart/RESET_CART')
    }

    const handleCreateOrder = e => {
      const remainingData = {
        customer: customer.value,
        pickupAddress: pickupAddress.value,
        deliveryAddress: deliveryAddress.value,
        promoCode: promoCode.value,
        hasFast: hasFast.value,
        hasPickup: hasPickup.value,
        hasDelivery: hasDelivery.value,
        pickupDay: pickupDay.value,
        deliveryDay: deliveryDay.value,
        pickupTime: pickupTime.value,
        deliveryTime: deliveryTime.value,
        // readyByTime: readyByTime.value,
      }
      store.commit('cart/ADD_REMAINING_DATA', remainingData)

      const cartItems = store.getters['cart/getItems']

      const items = cartItems.map(item => ({
        price_list_id: item.id,
        quantity: item.quantity,
        // eslint-disable-next-line no-nested-ternary
        service_type: item.selected_service === 'dry' ? 1 : item.selected_service === 'iron' ? 2 : item.selected_service === 'wash_iron' ? 3 : item.selected_service === 'dry_clean' ? 4 : '',
        item_price: item.item_price,
        total_price: item.total_price,
        note: item.notes,
      }))

      const data = {
        user_id: customer.value.id,
        type: hasFast.value ? 2 : 1,
        pickup_type_id: hasPickup.value ? 1 : 2,
        user_note: generalInstructions.value,
        items,
      }

      if (promoCode.value) {
        data.promo_code = promoCode.value
      }

      if (hasPickup.value) {
        if (pickupAddress.value) {
          data.pickup_address_id = pickupAddress.value
        }
        if (pickupTime.value) {
          data.pickup_time_id = pickupTime.value
        }
      }

      if (hasDelivery.value) {
        if (!deliveryAddress.value) {
          error.value = 'Delivery address required'
          return
        }
        if (!hasFast.value && !deliveryTime.value) {
          error.value = 'Delivery time required'
          return
        }
        if (deliveryAddress.value) {
          data.delivery_address_id = deliveryAddress.value
        }
        data.delivery_time_id = deliveryTime.value
      }

      submitButtonDisabled.value = true

      axios.post(`${process.env.VUE_APP_BASE_API_URL}/pos/laundry-orders`, data, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      }).then(res => {
        if (res.data.code === 201) {
          submitButtonDisabled.value = false
          error.value = ''

          party.confetti(e.target, {
            shapes: ['star', 'roundedSquare'],
          })

          toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
          })

          handleResetCart()

          setTimeout(() => {
            if (res.data.data.order.items.length > 0) {
              context.root.$router.push(`/orders/print/${res.data.data.order.id}`)
            } else {
              context.root.$router.push(`/orders/${res.data.data.order.id}`)
            }
          }, 500)
        }
      }).catch(err => {
        error.value = err.response.data.message
        submitButtonDisabled.value = false
      })
    }

    const handleEditOrder = e => {
      // const cart = store.getters['cart/getCart']
      const remainingData = {
        customer: customer.value,
        pickupAddress: pickupAddress.value,
        deliveryAddress: deliveryAddress.value,
        promoCode: promoCode.value,
        hasFast: hasFast.value,
        hasPickup: hasPickup.value,
        hasDelivery: hasDelivery.value,
        pickupDay: pickupDay.value,
        deliveryDay: deliveryDay.value,
        pickupTime: pickupTime.value,
        deliveryTime: deliveryTime.value,
        // readyByTime: readyByTime.value,
      }
      store.commit('cart/ADD_REMAINING_DATA', remainingData)

      const cartItems = store.getters['cart/getItems']

      const items = cartItems.map(item => ({
        price_list_id: item.id,
        quantity: item.quantity,
        // eslint-disable-next-line no-nested-ternary
        service_type: item.selected_service === 'dry' ? 1 : item.selected_service === 'iron' ? 2 : item.selected_service === 'wash_iron' ? 3 : item.selected_service === 'dry_clean' ? 4 : '',
        item_price: item.item_price,
        total_price: item.total_price,
        note: item.notes,
      }))

      const data = {
        user_id: customer.value.id,
        type: hasFast.value ? 2 : 1,
        pickup_type_id: hasPickup.value ? 1 : 2,
        user_note: generalInstructions.value,
        items,
      }

      if (promoCode.value) {
        data.promo_code = promoCode.value
      }

      if (hasPickup.value) {
        if (pickupAddress.value) {
          data.pickup_address_id = pickupAddress.value
        }
        if (pickupTime.value) {
          data.pickup_time_id = pickupTime.value
        }
      } else {
        data.pickup_address_id = null
        data.pickup_time_id = null
      }

      if (hasDelivery.value) {
        if (!deliveryAddress.value) {
          error.value = 'Delivery address required'
          return
        }
        if (!hasFast.value && !deliveryTime.value) {
          error.value = 'Delivery time required'
          return
        }
        if (deliveryAddress.value) {
          data.delivery_address_id = deliveryAddress.value
        }
        data.delivery_time_id = deliveryTime.value
      } else {
        data.delivery_address_id = null
        data.delivery_time_id = null
      }

      submitButtonDisabled.value = true

      axios.put(`${process.env.VUE_APP_BASE_API_URL}/pos/laundry-orders/${orderId}`, data, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      }).then(res => {
        if (res.data.code === 202) {
          submitButtonDisabled.value = false
          error.value = ''

          party.confetti(e.target, {
            shapes: ['star', 'roundedSquare'],
          })

          toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
          })

          handleResetCart()

          setTimeout(() => {
            if (res.data.data.order.items.length > 0) {
              context.root.$router.push(`/orders/print/${res.data.data.order.id}`)
            } else {
              context.root.$router.push(`/orders/${res.data.data.order.id}`)
            }
          }, 500)
        }
      }).catch(err => {
        error.value = err.response.data.message
        submitButtonDisabled.value = false
      })
    }

    return {
      skin,
      itemSidebarType,
      itemSearch,
      category,
      handleCategoryFilter,
      handleCategoryFilterAndSearch,
      categories,
      filteredItems,
      customers,
      customer,
      pickupAddress,
      deliveryAddress,
      userAddresses,
      selectedCustomer,
      handleCustomerSelect,
      handleCustomerSearch,
      handleCustomerRemove,
      handlePickupDaySelect,
      // handleDeliveryDaySelect,
      handlePromoCodeCheck,
      expressToggle,
      expressCost,
      expressHours,
      hasFast,
      hasPickup,
      hasDelivery,
      workingDays,
      pickupWorkingDays,
      deliveryWorkingDays,
      pickupWorkingHours,
      deliveryWorkingHours,
      pickupDay,
      deliveryDay,
      pickupTime,
      deliveryTime,
      userDiscount,
      promoCode,
      promoCodeInputDisabled,
      promoCodeMessage,
      promoCodeAmount,
      subTotal,
      total,
      grandTotal,
      generalInstructions,
      error,
      selectedItem,
      handleSelectedItem,
      dryCleanItems,
      washIronItems,
      ironItems,
      dryItems,
      totalItems,
      handleSidebarClose,
      handleDeleteGeneralInstructions,
      handleResetCart,
      handleCreateOrder,
      handleEditOrder,
      submitButtonDisabled,
      handlePromoCodeChange,
      orderId,
      loading,
      addItemOpened,
      handleAddItemSidebarOpen,
      handleAddItemSidebarClose,
      refreshPriceList,
      handleChangeGeneralInstructions,
      handlePromoCodeRemove,
    }
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
