import { ref, watch, computed } from '@vue/composition-api'
// import store from '@/store'
import axios from 'axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useOrders() {
  // Use toast
  const toast = useToast()

  const refOrdersTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: '#', sortable: true },
    { key: 'id', sortable: false },
    { key: 'invoice_id', sortable: false },
    { key: 'user', sortable: false },
    { key: 'type', sortable: false },
    { key: 'created_at', sortable: false },
    { key: 'price', sortable: false },
    { key: 'status', sortable: false },
    { key: 'payment', sortable: false },
    { key: 'details', label: '' },
  ]
  const perPage = ref(15)
  const totalOrders = ref(0)
  const currentPage = ref(1)
  const lastPage = ref(1)
  const perPageOptions = [15, 30, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const isCurrentFilter = ref('')
  const statusFilter = ref('')
  const paymentStatusFilter = ref('')
  const userFilter = ref('')
  const userIdFilter = ref('')
  const pickupDriverFilter = ref('')
  const deliveryDriverFilter = ref('')
  const createdAtFromFilter = ref('')
  const createdAtToFilter = ref('')
  const orderIdFilter = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refOrdersTable.value ? refOrdersTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalOrders.value,
    }
  })

  const refetchData = () => {
    refOrdersTable.value.refresh()
  }

  watch([currentPage, isCurrentFilter, statusFilter, paymentStatusFilter, perPage, searchQuery, userFilter, userIdFilter, pickupDriverFilter, deliveryDriverFilter, orderIdFilter], () => {
    refetchData()
  })

  watch([createdAtFromFilter, createdAtToFilter], () => {
    if ((createdAtFromFilter.value && createdAtToFilter.value) || (!createdAtFromFilter.value && !createdAtToFilter.value)) {
      refetchData()
    }
  })

  const fetchOrders = (ctx, callback) => {
    let url = `${process.env.VUE_APP_BASE_API_URL}/pos/laundry-orders?paginate&page=${currentPage.value}&perPage=${perPage.value}`

    if (isCurrentFilter.value !== '' && isCurrentFilter.value !== null) {
      url += `&is_current=${isCurrentFilter.value}`
    }

    if (orderIdFilter.value) {
      url += `&order_id=${orderIdFilter.value}`
    }

    if (statusFilter.value) {
      url += `&status=${statusFilter.value}`
    }

    if (paymentStatusFilter.value) {
      url += `&payment_status=${paymentStatusFilter.value}`
    }

    if (userFilter.value !== '' && userFilter.value !== null) {
      url += `&user_id=${userFilter.value}`
    }

    if (userIdFilter.value !== '' && userIdFilter.value !== null) {
      url += `&user_id=${userIdFilter.value}`
    }

    if (pickupDriverFilter.value !== '' && pickupDriverFilter.value !== null) {
      url += `&pickup_driver_id=${pickupDriverFilter.value}`
    }

    if (deliveryDriverFilter.value !== '' && deliveryDriverFilter.value !== null) {
      url += `&delivery_driver_id=${deliveryDriverFilter.value}`
    }

    if (createdAtFromFilter.value !== '' && createdAtFromFilter.value !== null && createdAtToFilter.value !== '' && createdAtToFilter.value !== null) {
      url += `&created_at_from=${createdAtFromFilter.value}&created_at_to=${createdAtToFilter.value}`
    }
    axios
      .get(url, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      })
      .then(res => {
        const orders = res.data.data.items
        totalOrders.value = res.data.data.total
        currentPage.value = res.data.data.current_page
        lastPage.value = res.data.data.last_page

        callback(orders)
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching orders list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchItems = () => (
    axios.get(`${process.env.VUE_APP_BASE_API_URL}/childPricelists`)
      .then(res => res.data.data.items)
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching items list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  )

  const fetchCategories = () => (
    axios.get(`${process.env.VUE_APP_BASE_API_URL}/pos/pricelist`, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    })
      .then(res => res.data.data.items)
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching categories list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  )

  const fetchCustomers = (searchTerm = '') => {
    let queryParams = '?paginate=1&page=1&perPage=15'

    if (searchTerm) {
      queryParams = `?search=${searchTerm}`
    }

    return axios.get(`${process.env.VUE_APP_BASE_API_URL}/pos/users${queryParams}`, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    })
      .then(res => res.data.data.items)
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching customers list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchExpressConfig = () => (
    axios.get(`${process.env.VUE_APP_BASE_API_URL}/express_service`, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    })
      .then(res => res.data.data)
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Express Config list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  )

  const fetchWorkingDays = () => (
    axios.get(`${process.env.VUE_APP_BASE_API_URL}/workingHours`, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    })
      .then(res => res.data.data.working_days)
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching working days list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  )

  const fetchDeliveryDays = (type, id) => (
    axios.get(`${process.env.VUE_APP_BASE_API_URL}/deliveryHours/${type}/${id}`, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    })
      .then(res => res.data.data.delivery_days)
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching delivery days list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  )

  const fetchOrder = id => (
    axios.get(`${process.env.VUE_APP_BASE_API_URL}/pos/laundry-orders/${id}`, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    })
      .then(res => res.data.data.order)
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching order',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  )

  const fetchUserAddresses = userId => (
    axios.get(`${process.env.VUE_APP_BASE_API_URL}/user-address/${userId}`, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    })
      .then(res => res.data.data.items)
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching user Addresses list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  )

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchOrders,
    fetchOrder,
    tableColumns,
    perPage,
    currentPage,
    lastPage,
    totalOrders,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refOrdersTable,
    isCurrentFilter,
    statusFilter,
    paymentStatusFilter,
    userFilter,
    userIdFilter,
    pickupDriverFilter,
    deliveryDriverFilter,
    createdAtFromFilter,
    createdAtToFilter,
    orderIdFilter,

    refetchData,
    fetchCategories,
    fetchItems,
    fetchCustomers,
    fetchWorkingDays,
    fetchUserAddresses,
    fetchExpressConfig,
    fetchDeliveryDays,
  }
}
