import { ref, watch, computed } from '@vue/composition-api'
// import store from '@/store'
import axios from 'axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCustomerTransactions() {
  // Use toast
  const toast = useToast()

  const refCustomerTransactionsTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: '#', sortable: true },
    { key: 'id', sortable: false },
    { key: 'paid_amount', sortable: false },
    { key: 'grand_total_price', sortable: false },
    { key: 'wallet_deducted_price', sortable: false },
    { key: 'subscription_deducted_price', sortable: false },
    { key: 'type', sortable: false },
    { key: 'invited_by', sortable: false },
    { key: 'payment_method', sortable: false },
    { key: 'created_at', sortable: false },
    { key: 'payment_date', sortable: false },
  ]
  const perPage = ref(15)
  const id = ref('')
  const totalCustomerTransactions = ref(0)
  const currentPage = ref(1)
  const lastPage = ref(1)
  const perPageOptions = [15, 30, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refCustomerTransactionsTable.value ? refCustomerTransactionsTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCustomerTransactions.value,
    }
  })

  const refetchData = () => {
    refCustomerTransactionsTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, id], () => {
    refetchData()
  })

  const fetchCustomerTransactions = (ctx, callback) => {
    axios
      .get(`${process.env.VUE_APP_BASE_API_URL}/pos/user/${id.value}/transactions?paginate&page=${currentPage.value}&perPage=${perPage.value}`, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      })
      .then(res => {
        const customerTransactions = res.data.data.items
        totalCustomerTransactions.value = res.data.data.total
        currentPage.value = res.data.data.current_page
        lastPage.value = res.data.data.last_page

        callback(customerTransactions)
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching Customer Transactions' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchCustomerTransactions,
    tableColumns,
    perPage,
    currentPage,
    lastPage,
    totalCustomerTransactions,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCustomerTransactionsTable,
    id,

    refetchData,
  }
}
