// import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  // base: process.env.VUE_APP_BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        requiresAuth: true,
        // breadcrumb: [
        //   {
        //     text: 'Home',
        //     active: true,
        //   },
        // ],
      },
    },
    {
      path: '/orders/create/:customerId?',
      name: 'create-order',
      component: () => import('@/layouts/components/orders/create/CreateOrder.vue'),
      meta: {
        pageTitle: 'Create Order',
        requiresAuth: true,
      },
    },
    {
      path: '/orders/edit/:id',
      name: 'edit-order',
      component: () => import('@/layouts/components/orders/create/CreateOrder.vue'),
      meta: {
        pageTitle: 'Edit Order',
        requiresAuth: true,
      },
    },
    {
      path: '/orders/:id?',
      name: 'orders',
      component: () => import('@/views/Orders.vue'),
      meta: {
        pageTitle: 'Orders',
        requiresAuth: true,
      },
    },
    {
      path: '/orders/print/:id',
      name: 'print-order',
      component: () => import('@/layouts/components/orders/OrderReceipt.vue'),
      meta: {
        pageTitle: 'Print Order',
        requiresAuth: true,
      },
    },
    {
      path: '/subscriptions/print/:id/:customerId',
      name: 'print-order',
      component: () => import('@/layouts/components/customers/customerDetails/SubscriptionReceipt.vue'),
      meta: {
        pageTitle: 'Print Subscription',
        requiresAuth: true,
      },
    },
    {
      path: '/customers/:id?',
      name: 'customers',
      component: () => import('@/views/Customers.vue'),
      meta: {
        pageTitle: 'Customers',
        requiresAuth: true,
      },
    },
    // {
    //   path: '/store-setup',
    //   name: 'store-setup',
    //   component: () => import('@/views/StoreSetup.vue'),
    //   meta: {
    //     pageTitle: 'Store Setup',
    //     requiresAuth: true,
    //   },
    // },
    // {
    //   path: '/system-settings',
    //   name: 'system-settings',
    //   component: () => import('@/views/SystemSettings.vue'),
    //   meta: {
    //     pageTitle: 'System Settings',
    //     requiresAuth: true,
    //   },
    // },
    // {
    //   path: '/second-page',
    //   name: 'second-page',
    //   component: () => import('@/views/SecondPage.vue'),
    //   meta: {
    //     pageTitle: 'Second Page',
    //     // breadcrumb: [
    //     //   {
    //     //     text: 'Second Page',
    //     //     active: true,
    //     //   },
    //     // ],
    //   },
    // },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/authentication/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forot-pasword',
      name: 'forgot-password',
      component: () => import('@/views/authentication/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import('@/views/authentication/ResetPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const token = localStorage.getItem('token')
    if (!token) {
      next({
        name: 'login',
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
